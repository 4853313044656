<template>
    <div>
        <a @click="$router.back()" href="#" class="
          text-dark-75
          font-weight-bolder
          text-hover-primary
          mb-1
          font-size-lg
          d-flex
          flex-row
          align-items-center
        ">
            <i class="mdi mdi-chevron-left"></i>
            Kembali
        </a>
        <validation-observer ref="formSiswa">
            <b-form @submit.prevent="submitForm" @reset="onReset">
                <div class="card card-custom card-stretch">
                    <div class="card-header border-0 py-5">
                        <h3 class="card-title align-items-start flex-column">
                            <span class="card-label font-weight-bolder text-dark">
                                Buat Region
                            </span>
                        </h3>
                        <div class="card-toolbar">
                            <button type="submit" class="btn btn-success font-weight-bolder font-size-sm mr-2">
                                Simpan
                            </button>
                            <span @click="$router.back()" class="btn btn-secondary font-weight-bolder font-size-sm">
                                Batal</span>
                        </div>
                    </div>
                    <div class="card-body pt-4">
                        <b-form-group id="input-group-1" label="Nama Region:" label-for="input-1">
                            <validation-provider #default="{ errors }" name="Nama Region" rules="required">
                                <b-form-input :state="errors.length > 0 ? false : null" id="input-1"
                                    v-model="form.body.region_name" type="text"
                                    placeholder="Masukkan Region"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                </div>
            </b-form>
        </validation-observer>
    </div>
</template>
  
<script>
import { mapState, mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import {
    CREATE_REGION,
    UPDATE_REGION,
} from "@/core/services/store/region.module";

import Swal from "sweetalert2";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import mixin from "@/core/services/Helpers";
export default {
    name: "RegionForm",
    mixins: [mixin],
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    data() {
        return {
            form: {
                body: {
                    region_name: '',
                },
            },
            isLoading: false,
        };
    },
    methods: {
        submitForm() {
            this.$refs.formSiswa.validate().then((success) => {
                this.isLoading = true;
                if (success) {
                    if (this.Id) {
                        this.form.body._id = this.currentRegion._id;
                    } else {
                        this.form.body.vendor_id = this.vendorId;
                    }

                    const _data = JSON.parse(JSON.stringify(this.form.body));
                    this.$store
                        .dispatch(this.Id ? UPDATE_REGION : CREATE_REGION, _data)
                        .then((response) => {
                            Swal.fire({
                                title: response.message,
                                text: `Berhasil ${this.Id ? "mengubah" : "membuat"
                                    } Region`,
                                icon: "success",
                                confirmButtonClass: "btn btn-secondary",
                                heightAuto: false,
                            });
                            this.$router.push(`/region`);
                            this.isLoading = false;
                        })
                        .catch(() => {
                            this.isLoading = false;
                            this.$bvToast.toast(this.errors, {
                                title: `Gagal ${this.Id ? "mengubah" : "membuat"} Region`,
                                variant: "danger",
                                solid: true,
                            });
                        });
                }
            });
        },
        onReset() { },
    },
    mounted() {
        if (this.Id) {
            const selected = this.currentRegion;

            if (selected.region_name === undefined) {
                this.$router.back();
            }

            this.form = {
                body: {
                    region_name: selected.region_name,
                },
            };
        }
    },
    computed: {
        ...mapState({
            errors: (state) => state.auth.errors,
        }),
        ...mapGetters(["currentRegion"]),
        Id() {
            return this.$route.params.id;
        },
        vendorId() {
            const user = this.$store.getters.authUser;
            return this.getVendorId(user);
        }
    },
};
</script>
  